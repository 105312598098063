import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const ConsignacionImg = () => (
  <StaticImage
    src="../../images/consignacion.png"
    alt="Orozco Laverde"
  />
);

export default ConsignacionImg;
