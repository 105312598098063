import React from "react";
import { Container, Row, Col } from "reactstrap";
import PageLayout from "../components/PageLayout";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandsHelping,
  faThumbsUp,
  faComments,
} from "@fortawesome/free-solid-svg-icons";

import ConsignacionImg from "../components/Images/consignacion-img";

const PublicarPage = () => (
  <PageLayout>
    <Container className="page-layout">
      <Row>
        <Col className="mt-5">
          <h3 className="text-center text-uppercase">
            Venta de finca ra&iacute;z
          </h3>
          <hr className="title-line" />
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="6" lg={{ size: 4, offset: 2 }} className="mt-4">
          <ConsignacionImg />
        </Col>
        <Col md="6">
          <ul className="welcome-list list-unstyled mt-4 text-center">
            <li className="mb-3">
              <h6>
                <FontAwesomeIcon
                  icon={faComments}
                  className="w-100 text-center mb-3"
                  size="3x"
                  color="#BCA068"
                />
                Cont&aacute;ctenos a trav&eacute;s de nuestros canales.
              </h6>
              <div>
                <a href={`mailto:${process.env.GATSBY_VENTAS_EMAIL}`}>
                  {process.env.GATSBY_VENTAS_EMAIL}
                </a>
                <a
                  href={`tel:${process.env.GATSBY_VENTAS_CELLPHONE}`}
                  className="d-block mt-3"
                >
                  {process.env.GATSBY_VENTAS_CELLPHONE_DISPLAY}
                </a>
              </div>
            </li>
            <li className="mb-3">
              <h6>
                <FontAwesomeIcon
                  icon={faHandsHelping}
                  className="w-100 text-center mb-3"
                  size="3x"
                  color="#BCA068"
                />
                Uno de nuestros asesores lo visitar&aacute; para acompañarlo en
                el proceso.
              </h6>
            </li>
            <li className="mb-3">
              <h6>
                <FontAwesomeIcon
                  icon={faThumbsUp}
                  className="w-100 text-center mb-3"
                  size="3x"
                  color="#BCA068"
                  fixedWidth
                />
                Su inmueble ser&aacute; publicado en nuestra p&aacute;gina web,
                metrocuadrado.com y fincaraiz.com
              </h6>
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 9, offset: 2 }}>
          <h4 className="text-center mt-5">Requisitos</h4>
          <hr className="title-line" />
          <ul className="welcome-list">
            <li>Fotocopia cedula(s) de (los) propietario(s).</li>
            <li>
              Fotocopia certificado de tradici&oacute;n y Libertad del inmueble
              reciente.
            </li>
            <li>Fotocopia &uacute;ltimo impuesto predial.</li>
            <li>Diligenciar contrato de consignaci&oacute;n del inmueble.</li>
          </ul>
        </Col>
      </Row>
    </Container>
  </PageLayout>
);

export default PublicarPage;
