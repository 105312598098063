import styled from "styled-components";

const StyledPageLayout = styled.div`
  .nav-divider {
    color: $dark-golden;
  }

  .welcome-list {
      color: $darker-golden;
  }

  .slick-slider {
    position: relative;
    .slick-prev, .slick-next {
      position: absolute;
      top: 50%;
    }
    .slick-prev {
      &:before {
        font-size: 28px;
        color: #FFF;
      }
      left: 3%;
      z-index: 1;
    }
    .slick-next {
      &:before {
        font-size: 28px;
        color: #FFF;
      }
      right: 3%;
    }
  }
`

export default StyledPageLayout;
