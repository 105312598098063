import React from "react";
import Navigation from "../Navigation";
import Whatsapp from "../Whatsapp";
import Footer from "../Footer";
import StyledPageLayout from "./styles";

const PageLayout = ({ children }) => (
  <StyledPageLayout>
    <Navigation />
    {/* <Whatsapp /> */}
    {children}
    <Footer />
  </StyledPageLayout>
);

export default PageLayout;
